import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { useUID } from '@twilio-paste/uid-library';
import { useComboboxPrimitive } from '@twilio-paste/combobox-primitive';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { ChevronDownIcon } from '@twilio-paste/icons/esm/ChevronDownIcon';
import { FormLabel, FormInput } from '@twilio-paste/form';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { autocompleteExample, defaultExample } from '../../../component-examples/ComboboxPrimitiveExamples';
import Changelog from '@twilio-paste/combobox-primitive/CHANGELOG.md';
export const pageQuery = graphql`
  {
    allPastePrimitive(filter: {name: {eq: "@twilio-paste/combobox-primitive"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/primitives/combobox-primitive/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Combobox Primitive" categoryRoute={SidebarCategoryRoutes.PRIMITIVES} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/primitives/combobox" storybookUrl="/?path=/story/primitives-combobox--dropdown-combobox" data={props.data.allPastePrimitive.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About the Combobox Primitive`}</h3>
        <p>{`This package provides a foundation upon which developers can implement a `}<a parentName="p" {...{
            "href": "https://www.w3.org/TR/wai-aria-practices/examples/combobox/aria1.1pattern/listbox-combo.html"
          }}>{`WAI-Aria compliant Combobox`}</a>{`.
It can be used to build functional and accessible Comboboxes with or without autocomplete/typeahead features.
Our Combobox is built on top of this primitive.`}</p>
        <p>{`The purpose of providing these unstyled primitives is to cater for instances when the styled Combobox provided
by Paste, doesn't meet the requirements needed to solve a unique or individual customer problem. At that point
you are welcome to fallback to this functional primitive to roll your own styled Combobox whilst still providing
a functional and accessible experience to your customers.`}</p>
        <p>{`This primitive should be used to compose all custom Comboboxes to ensure accessibility and upgrade paths.`}</p>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Warning</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    We strongly suggest that all components built on top of this primitive get reviewed by the Design Systems team and
    goes through the UX Review process to ensure an excellent experience for our customers.
  </CalloutText>
        </Callout>
        <h2>{`Usage Guide`}</h2>
        <p>{`This package is a wrapper around the `}<a parentName="p" {...{
            "href": "https://downshift.netlify.com"
          }}><inlineCode parentName="a">{`Downshift`}</inlineCode>{` package`}</a>{`.
Our wrapper currently only exposes the useCombobox hook, but renamed for Paste. The reason we chose
to just expose the hook is that we feel it is the most flexible way of consuming downshift and better
fit our chosen styling model.`}</p>
        <p>{`If you’re wondering why we wrapped that package into our own, we reasoned that it would be
best for our consumers' developer experience. For example:`}</p>
        <ul>
          <li parentName="ul">{`If we want to migrate the underlying nuts and bolts in the future, Twilio products
that depend on this primitive would need to replace all occurrences of `}<inlineCode parentName="li">{`import … from ‘x-package’`}</inlineCode>{`
to `}<inlineCode parentName="li">{`import … from ‘@some-new/package’`}</inlineCode>{`. By wrapping it in `}<inlineCode parentName="li">{`@twilio-paste/x-primitive`}</inlineCode>{`, this
refactor can be avoided. The only change would be a version bump in the package.json file for the primitive.`}</li>
          <li parentName="ul">{`We can more strictly enforce semver and backwards compatibility than some of our dependencies.`}</li>
          <li parentName="ul">{`We can control when to provide an update and which versions we allow, to help reduce potential
bugs our consumers may face.`}</li>
          <li parentName="ul">{`We can control which APIs we expose. For example, we may chose to enable or disable usage of
certain undocumented APIs.`}</li>
        </ul>
        <h3>{`Installation`}</h3>
        <p>{`This package is available individually or as part of `}<inlineCode parentName="p">{`@twilio-paste/core`}</inlineCode>{`.`}</p>
        <pre><code parentName="pre" {...{}}>{`yarn add @twilio-paste/combobox-primitive - or - yarn add @twilio-paste/core
`}</code></pre>
        <h3>{`Examples`}</h3>
        <h4>{`Basic Combobox`}</h4>
        <p>{`A basic Combobox is a direct replacement for the native HTML select element. It should function in roughly
the same way, the difference being in the ability to style everything about it; the input trigger, option list
and option content.`}</p>
        <p>{`Contrary to the Downshift documentation and example, the basic Combobox should have an HTML input element as
the trigger, and not an HTML button. Use an input element and set the role of Combobox in the `}<inlineCode parentName="p">{`getToggleButtonProps`}</inlineCode>{`
getter or directly on the element itself. You can see this demonstrated below:`}</p>
        <Callout mdxType="Callout">
  <CalloutText mdxType="CalloutText">
    Using an input element prevents screen readers like JAWS switching out of focus or forms mode when navigating
    through a form. This provides a really frustrating experience to users of JAWS. It is also a direct replacement for
    a form element, so the value should be accessible via the form submit event, which a button element will not
    provide.
  </CalloutText>
        </Callout>
        <LivePreview scope={{
          useComboboxPrimitive,
          Box,
          FormLabel,
          FormInput,
          useUID
        }} noInline language="jsx" mdxType="LivePreview">
  {defaultExample}
        </LivePreview>
        <h4>{`Autcomplete Combobox Example`}</h4>
        <p>{`This hook can be used to create custom autocomplete Combobox controls. These controls are useful when the
customer needs to filter a list of available options, or provide a custom free form value to the input.`}</p>
        <LivePreview scope={{
          useComboboxPrimitive,
          Box,
          Button,
          ChevronDownIcon,
          FormLabel,
          FormInput,
          useUID
        }} noInline language="jsx" mdxType="LivePreview">
  {autocompleteExample}
        </LivePreview>
        <h3>{`useComboboxPrimitive Arguments`}</h3>
        <h4>{`Basic Props`}</h4>
        <p>{`This is the list of props that you should probably know about. There are some
`}<a parentName="p" {...{
            "href": "#advanced-props"
          }}>{`advanced props`}</a>{` below as well.`}</p>
        <h5>{`items `}<inlineCode parentName="h5">{`any[]`}</inlineCode>{` | `}<em parentName="h5">{`required`}</em></h5>
        <p>{`The main difference from vanilla `}<inlineCode parentName="p">{`Downshift`}</inlineCode>{` is that we pass the items we want
to render to the hook as well. Opening the menu with an item already selected
means the hook has to know in advance what items you plan to render and what is
the position of that item in the list. Consequently, there won't be any need for
two state changes: one for opening the menu and one for setting the highlighted
index, like in `}<inlineCode parentName="p">{`Downshift`}</inlineCode>{`.`}</p>
        <h5>{`itemToString `}<inlineCode parentName="h5">{`function(item: any)`}</inlineCode>{` | defaults to: `}<inlineCode parentName="h5">{`item => (item ? String(item) : '')`}</inlineCode></h5>
        <p>{`If your items are stored as, say, objects instead of strings, downshift still
needs a string representation for each one. This is required for accessibility
messages (e.g., after making a selection).`}</p>
        <p><strong parentName="p">{`Note:`}</strong>{` This callback `}<em parentName="p">{`must`}</em>{` include a null check: it is invoked with `}<inlineCode parentName="p">{`null`}</inlineCode>{`
whenever the user abandons input via `}<inlineCode parentName="p">{`<Esc>`}</inlineCode>{`.`}</p>
        <h5>{`onSelectedItemChange `}<inlineCode parentName="h5">{`function(changes: object)`}</inlineCode>{` | optional, no useful default`}</h5>
        <p>{`Called each time the selected item was changed. Selection can be performed by
item click, Enter Key while item is highlighted or by blurring the menu while an
item is highlighted (Tab, Shift-Tab or clicking away).`}</p>
        <ul>
          <li parentName="ul"><inlineCode parentName="li">{`changes`}</inlineCode>{`: These are the properties that actually have changed since the last
state change. This object is guaranteed to contain the `}<inlineCode parentName="li">{`selectedItem`}</inlineCode>{` property
with the newly selected value. This also has a `}<inlineCode parentName="li">{`type`}</inlineCode>{` property which you can
learn more about in the `}<a parentName="li" {...{
              "href": "#statechangetypes"
            }}><inlineCode parentName="a">{`stateChangeTypes`}</inlineCode></a>{` section. This
property will be part of the actions that can trigger a `}<inlineCode parentName="li">{`selectedItem`}</inlineCode>{` change,
for example `}<inlineCode parentName="li">{`useCombobox.stateChangeTypes.ItemClick`}</inlineCode>{`.`}</li>
        </ul>
        <h5>{`stateReducer `}<inlineCode parentName="h5">{`function(state: object, actionAndChanges: object)`}</inlineCode>{` | optional`}</h5>
        <p><strong parentName="p">{`🚨 This is a really handy power feature 🚨`}</strong></p>
        <p>{`This function will be called each time `}<inlineCode parentName="p">{`useCombobox`}</inlineCode>{` sets its internal state (or
calls your `}<inlineCode parentName="p">{`onStateChange`}</inlineCode>{` handler for control props). It allows you to modify
the state change that will take place which can give you fine grain control over
how the component interacts with user updates. It gives you the current state
and the state that will be set, and you return the state that you want to set.`}</p>
        <ul>
          <li parentName="ul"><inlineCode parentName="li">{`state`}</inlineCode>{`: The full current state of downshift.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`actionAndChanges`}</inlineCode>{`: Object that contains the action `}<inlineCode parentName="li">{`type`}</inlineCode>{`, props needed to
return a new state based on that type and the changes suggested by the
Downshift default reducer. About the `}<inlineCode parentName="li">{`type`}</inlineCode>{` property you can learn more about
in the `}<a parentName="li" {...{
              "href": "#statechangetypes"
            }}><inlineCode parentName="a">{`stateChangeTypes`}</inlineCode></a>{` section.`}</li>
        </ul>
        <h4>{`Advanced Props`}</h4>
        <h5>{`initialSelectedItem `}<inlineCode parentName="h5">{`any`}</inlineCode>{` | defaults to `}<inlineCode parentName="h5">{`null`}</inlineCode></h5>
        <p>{`Pass an item that should be selected when downshift is initialized.`}</p>
        <h5>{`initialIsOpen `}<inlineCode parentName="h5">{`boolean`}</inlineCode>{` | defaults to `}<inlineCode parentName="h5">{`false`}</inlineCode></h5>
        <p>{`Pass a boolean that sets the open state of the menu when downshift is
initialized.`}</p>
        <h5>{`initialHighlightedIndex `}<inlineCode parentName="h5">{`number`}</inlineCode>{` | defaults to `}<inlineCode parentName="h5">{`-1`}</inlineCode></h5>
        <p>{`Pass a number that sets the index of the highlighted item when downshift is
initialized.`}</p>
        <h5>{`initialInputValue `}<inlineCode parentName="h5">{`string`}</inlineCode>{` | defaults to `}<inlineCode parentName="h5">{`''`}</inlineCode></h5>
        <p>{`Pass a string that sets the content of the input when downshift is initialized.`}</p>
        <h5>{`defaultSelectedItem `}<inlineCode parentName="h5">{`any`}</inlineCode>{` | defaults to `}<inlineCode parentName="h5">{`null`}</inlineCode></h5>
        <p>{`Pass an item that should be selected when downshift is reset.`}</p>
        <h5>{`defaultIsOpen `}<inlineCode parentName="h5">{`boolean`}</inlineCode>{` | defaults to `}<inlineCode parentName="h5">{`false`}</inlineCode></h5>
        <p>{`Pass a boolean that sets the open state of the menu when downshift is reset or
when an item is selected.`}</p>
        <h5>{`defaultHighlightedIndex `}<inlineCode parentName="h5">{`number`}</inlineCode>{` | defaults to `}<inlineCode parentName="h5">{`-1`}</inlineCode></h5>
        <p>{`Pass a number that sets the index of the highlighted item when downshift is
reset or when an item is selected.`}</p>
        <h5>{`defaultInputValue `}<inlineCode parentName="h5">{`string`}</inlineCode>{` | defaults to `}<inlineCode parentName="h5">{`''`}</inlineCode></h5>
        <p>{`Pass a string that sets the content of the input when downshift is reset or when
an item is selected.`}</p>
        <h5>{`getA11yStatusMessage `}<inlineCode parentName="h5">{`function({/* see below */})`}</inlineCode>{` | default messages provided in English`}</h5>
        <p>{`This function is passed as props to a status updating function nested within
that allows you to create your own ARIA statuses. It is called when one of the
following props change: `}<inlineCode parentName="p">{`items`}</inlineCode>{`, `}<inlineCode parentName="p">{`highlightedIndex`}</inlineCode>{`, `}<inlineCode parentName="p">{`inputValue`}</inlineCode>{` or `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{`.`}</p>
        <p>{`A default `}<inlineCode parentName="p">{`getA11yStatusMessage`}</inlineCode>{` function is provided that will check
`}<inlineCode parentName="p">{`resultCount`}</inlineCode>{` and return "No results are available." or if there are results ,
"`}<inlineCode parentName="p">{`resultCount`}</inlineCode>{` results are available, use up and down arrow keys to navigate.
Press Enter key to select."`}</p>
        <h5>{`getA11ySelectionMessage `}<inlineCode parentName="h5">{`function({/* see below */})`}</inlineCode>{` | default messages provided in English`}</h5>
        <p>{`This function is similar to the `}<inlineCode parentName="p">{`getA11yStatusMessage`}</inlineCode>{` but it is generating a
message when an item is selected. It is passed as props to a status updating
function nested within that allows you to create your own ARIA statuses. It is
called when `}<inlineCode parentName="p">{`selectedItem`}</inlineCode>{` changes.`}</p>
        <p>{`A default `}<inlineCode parentName="p">{`getA11ySelectionMessage`}</inlineCode>{` function is provided. When an item is
selected, the message is a selection related one, narrating
"`}<inlineCode parentName="p">{`itemToString(selectedItem)`}</inlineCode>{` has been selected".`}</p>
        <p>{`The object you are passed to generate your status message, for both
`}<inlineCode parentName="p">{`getA11yStatusMessage`}</inlineCode>{` and `}<inlineCode parentName="p">{`getA11ySelectionMessage`}</inlineCode>{`, has the following
properties:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`highlightedIndex`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`number`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The currently highlighted index`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`highlightedItem`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`any`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The value of the highlighted item`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`isOpen`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The `}<inlineCode parentName="td">{`isOpen`}</inlineCode>{` state`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`inputValue`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The value in the text input.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`itemToString`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`function(any)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The `}<inlineCode parentName="td">{`itemToString`}</inlineCode>{` function (see props) for getting the string value from one of the options`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`previousResultCount`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`number`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The total items showing in the dropdown the last time the status was updated`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`resultCount`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`number`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The total items showing in the dropdown`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`selectedItem`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`any`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The value of the currently selected item`}</td>
            </tr>
          </tbody>
        </table>
        <h5>{`onHighlightedIndexChange `}<inlineCode parentName="h5">{`function(changes: object)`}</inlineCode>{` | optional, no useful default`}</h5>
        <p>{`Called each time the highlighted item was changed. Items can be highlighted
while hovering the mouse over them or by keyboard keys such as Up Arrow, Down
Arrow, Home and End. Arrow keys can be combined with Shift to move by a step of
5 positions instead of 1. Items can also be highlighted by hitting character
keys that are part of their starting string equivalent.`}</p>
        <ul>
          <li parentName="ul"><inlineCode parentName="li">{`changes`}</inlineCode>{`: These are the properties that actually have changed since the last
state change. This object is guaranteed to contain the `}<inlineCode parentName="li">{`highlightedIndex`}</inlineCode>{`
property with the new value. This also has a `}<inlineCode parentName="li">{`type`}</inlineCode>{` property which you can
learn more about in the `}<a parentName="li" {...{
              "href": "#statechangetypes"
            }}><inlineCode parentName="a">{`stateChangeTypes`}</inlineCode></a>{` section. This
property will be part of the actions that can trigger a `}<inlineCode parentName="li">{`highlightedIndex`}</inlineCode>{`
change, for example `}<inlineCode parentName="li">{`useCombobox.stateChangeTypes.MenuKeyDownArrowUp`}</inlineCode>{`.`}</li>
        </ul>
        <h5>{`onIsOpenChange `}<inlineCode parentName="h5">{`function(changes: object)`}</inlineCode>{` | optional, no useful default`}</h5>
        <p>{`Called each time the menu is open or closed. Menu can be open by toggle button
click, Enter, Space, Up Arrow or Down Arrow keys. Can be closed by selecting an
item, blur (Tab, Shift-Tab or clicking outside), clicking the toggle button
again or hitting Escape key.`}</p>
        <ul>
          <li parentName="ul"><inlineCode parentName="li">{`changes`}</inlineCode>{`: These are the properties that actually have changed since the last
state change. This object is guaranteed to contain the `}<inlineCode parentName="li">{`isOpen`}</inlineCode>{` property with
the new value. This also has a `}<inlineCode parentName="li">{`type`}</inlineCode>{` property which you can learn more about
in the `}<a parentName="li" {...{
              "href": "#statechangetypes"
            }}><inlineCode parentName="a">{`stateChangeTypes`}</inlineCode></a>{` section. This property will be
part of the actions that can trigger a `}<inlineCode parentName="li">{`isOpen`}</inlineCode>{` change, for example
`}<inlineCode parentName="li">{`useCombobox.stateChangeTypes.ToggleButtonClick`}</inlineCode>{`.`}</li>
        </ul>
        <h5>{`onInputValueChange `}<inlineCode parentName="h5">{`function(changes: object)`}</inlineCode>{` | optional, no useful default`}</h5>
        <p>{`Called each time the value in the input text changes. The input value should
change like any input of type text, at any character key press, `}<inlineCode parentName="p">{`Space`}</inlineCode>{`,
`}<inlineCode parentName="p">{`Backspace`}</inlineCode>{`, `}<inlineCode parentName="p">{`Escape`}</inlineCode>{` etc.`}</p>
        <ul>
          <li parentName="ul"><inlineCode parentName="li">{`changes`}</inlineCode>{`: These are the properties that actually have changed since the last
state change. This object is guaranteed to contain the `}<inlineCode parentName="li">{`inputValue`}</inlineCode>{` property
with the new value. This also has a `}<inlineCode parentName="li">{`type`}</inlineCode>{` property which you can learn more
about in the `}<a parentName="li" {...{
              "href": "#statechangetypes"
            }}><inlineCode parentName="a">{`stateChangeTypes`}</inlineCode></a>{` section. This property
will be part of the actions that can trigger a `}<inlineCode parentName="li">{`inputValue`}</inlineCode>{` change, for
example `}<inlineCode parentName="li">{`useCombobox.stateChangeTypes.InputChange`}</inlineCode>{`.`}</li>
        </ul>
        <h5>{`onStateChange `}<inlineCode parentName="h5">{`function(changes: object)`}</inlineCode>{` | optional, no useful default`}</h5>
        <p>{`This function is called anytime the internal state changes. This can be useful
if you're using downshift as a "controlled" component, where you manage some or
all of the state (e.g., isOpen, selectedItem, highlightedIndex, etc) and then
pass it as props, rather than letting downshift control all its state itself.`}</p>
        <ul>
          <li parentName="ul"><inlineCode parentName="li">{`changes`}</inlineCode>{`: These are the properties that actually have changed since the last
state change. This also has a `}<inlineCode parentName="li">{`type`}</inlineCode>{` property which you can learn more about
in the `}<a parentName="li" {...{
              "href": "#statechangetypes"
            }}><inlineCode parentName="a">{`stateChangeTypes`}</inlineCode></a>{` section.`}</li>
        </ul>
        <h5>{`highlightedIndex`}</h5>
        <blockquote>
          <p parentName="blockquote"><inlineCode parentName="p">{`number`}</inlineCode></p>
        </blockquote>
        <p>{`The index of the item that should be highlighted when menu is open.`}</p>
        <h5>{`isOpen `}<inlineCode parentName="h5">{`boolean`}</inlineCode></h5>
        <p>{`The open state of the menu.`}</p>
        <h5>{`selectedItem `}<inlineCode parentName="h5">{`any`}</inlineCode></h5>
        <p>{`The item that should be selected.`}</p>
        <h5>{`inputValue `}<inlineCode parentName="h5">{`string`}</inlineCode></h5>
        <p>{`The value to be displayed in the text input.`}</p>
        <h5>{`id `}<inlineCode parentName="h5">{`string`}</inlineCode>{` | defaults to a generated ID`}</h5>
        <p>{`Used to generate the first part of the `}<inlineCode parentName="p">{`Downshift`}</inlineCode>{` id on the elements. You can
override this `}<inlineCode parentName="p">{`id`}</inlineCode>{` with one of your own, provided as a prop, or you can override
the `}<inlineCode parentName="p">{`id`}</inlineCode>{` for each element altogether using the props below.`}</p>
        <h5>{`labelId `}<inlineCode parentName="h5">{`string`}</inlineCode>{` | defaults to a generated ID`}</h5>
        <p>{`Used for `}<inlineCode parentName="p">{`aria`}</inlineCode>{` attributes and the `}<inlineCode parentName="p">{`id`}</inlineCode>{` prop of the element (`}<inlineCode parentName="p">{`label`}</inlineCode>{`) you use
`}<a parentName="p" {...{
            "href": "#getlabelprops"
          }}><inlineCode parentName="a">{`getLabelProps`}</inlineCode></a>{` with.`}</p>
        <h5>{`menuId `}<inlineCode parentName="h5">{`string`}</inlineCode>{` | defaults to a generated ID`}</h5>
        <p>{`Used for `}<inlineCode parentName="p">{`aria`}</inlineCode>{` attributes and the `}<inlineCode parentName="p">{`id`}</inlineCode>{` prop of the element (`}<inlineCode parentName="p">{`ul`}</inlineCode>{`) you use
`}<a parentName="p" {...{
            "href": "#getmenuprops"
          }}><inlineCode parentName="a">{`getMenuProps`}</inlineCode></a>{` with.`}</p>
        <h5>{`toggleButtonId `}<inlineCode parentName="h5">{`string`}</inlineCode>{` | defaults to a generated ID`}</h5>
        <p>{`Used for `}<inlineCode parentName="p">{`aria`}</inlineCode>{` attributes and the `}<inlineCode parentName="p">{`id`}</inlineCode>{` prop of the element (`}<inlineCode parentName="p">{`button`}</inlineCode>{`) you use
`}<a parentName="p" {...{
            "href": "#gettogglebuttonprops"
          }}><inlineCode parentName="a">{`getToggleButtonProps`}</inlineCode></a>{` with.`}</p>
        <h5>{`inputId `}<inlineCode parentName="h5">{`string`}</inlineCode>{` | defaults to a generated ID`}</h5>
        <p>{`Used for `}<inlineCode parentName="p">{`aria`}</inlineCode>{` attributes and the `}<inlineCode parentName="p">{`id`}</inlineCode>{` prop of the element (`}<inlineCode parentName="p">{`input`}</inlineCode>{`) you use
`}<a parentName="p" {...{
            "href": "#getmenuprops"
          }}><inlineCode parentName="a">{`getInputProps`}</inlineCode></a>{` with.`}</p>
        <h5>{`getItemId `}<inlineCode parentName="h5">{`function(index)`}</inlineCode>{` | defaults to a function that generates an ID based on the`}</h5>
        <blockquote>
          <p parentName="blockquote">{`index`}</p>
        </blockquote>
        <p>{`Used for `}<inlineCode parentName="p">{`aria`}</inlineCode>{` attributes and the `}<inlineCode parentName="p">{`id`}</inlineCode>{` prop of the element (`}<inlineCode parentName="p">{`li`}</inlineCode>{`) you use
`}<a parentName="p" {...{
            "href": "#getitemprops"
          }}><inlineCode parentName="a">{`getItemProps`}</inlineCode></a>{` with.`}</p>
        <h5>{`environment `}<inlineCode parentName="h5">{`window`}</inlineCode>{` | defaults to `}<inlineCode parentName="h5">{`window`}</inlineCode></h5>
        <p>{`This prop is only useful if you're rendering downshift within a different
`}<inlineCode parentName="p">{`window`}</inlineCode>{` context from where your JavaScript is running; for example, an iframe
or a shadow-root. If the given context is lacking `}<inlineCode parentName="p">{`document`}</inlineCode>{` and/or
`}<inlineCode parentName="p">{`add|removeEventListener`}</inlineCode>{` on its prototype (as is the case for a shadow-root)
then you will need to pass in a custom object that is able to provide
`}<a parentName="p" {...{
            "href": "https://gist.github.com/Rendez/1dd55882e9b850dd3990feefc9d6e177"
          }}>{`access to these properties`}</a>{`
for downshift.`}</p>
        <h5>{`circularNavigation `}<inlineCode parentName="h5">{`boolean`}</inlineCode>{` | defaults to `}<inlineCode parentName="h5">{`true`}</inlineCode></h5>
        <p>{`Controls the circular keyboard navigation between items. If set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, when
first item is highlighted, the Arrow Up will move highlight to the last item,
and viceversa using Arrow Down.`}</p>
        <h3>{`useComboboxPrimitive Returned`}</h3>
        <h5>{`getLabelProps`}</h5>
        <p>{`This method should be applied to the `}<inlineCode parentName="p">{`label`}</inlineCode>{` you render. It will generate an
`}<inlineCode parentName="p">{`id`}</inlineCode>{` that will be used to label the toggle button and the menu.`}</p>
        <p>{`There are no required properties for this method.`}</p>
        <blockquote>
          <p parentName="blockquote">{`Note: For accessibility purposes, calling this method is highly recommended.`}</p>
        </blockquote>
        <h5>{`getMenuProps`}</h5>
        <p>{`This method should be applied to the element which contains your list of items.
Typically, this will be a `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` or a `}<inlineCode parentName="p">{`<ul>`}</inlineCode>{` that surrounds a `}<inlineCode parentName="p">{`map`}</inlineCode>{` expression.
This handles the proper ARIA roles and attributes.`}</p>
        <p>{`Optional properties:`}</p>
        <ul>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`refKey`}</inlineCode>{`: if you're rendering a composite component, that component will need
to accept a prop which it forwards to the root DOM element. Commonly, folks
call this `}<inlineCode parentName="p">{`innerRef`}</inlineCode>{`. So you'd call: `}<inlineCode parentName="p">{`getMenuProps({refKey: 'innerRef'})`}</inlineCode>{` and
your composite component would forward like: `}<inlineCode parentName="p">{`<ul ref={props.innerRef} />`}</inlineCode>{`.
However, if you are just rendering a primitive component like `}<inlineCode parentName="p">{`<div>`}</inlineCode>{`, there
is no need to specify this property. It defaults to `}<inlineCode parentName="p">{`ref`}</inlineCode>{`.`}</p>
            <p parentName="li">{`Please keep in mind that menus, for accessiblity purposes, should always be
rendered, regardless of whether you hide it or not. Otherwise, `}<inlineCode parentName="p">{`getMenuProps`}</inlineCode>{`
may throw error if you unmount and remount the menu.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`aria-label`}</inlineCode>{`: By default the menu will add an `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` that refers to
the `}<inlineCode parentName="p">{`<label>`}</inlineCode>{` rendered with `}<inlineCode parentName="p">{`getLabelProps`}</inlineCode>{`. However, if you provide
`}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` to give a more specific label that describes the options
available, then `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` will not be provided and screen readers can
use your `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` instead.`}</p>
          </li>
        </ul>
        <p>{`In some cases, you might want to completely bypass the `}<inlineCode parentName="p">{`refKey`}</inlineCode>{` check. Then you
can provide the object `}<inlineCode parentName="p">{`{suppressRefError : true}`}</inlineCode>{` as the second argument to
`}<inlineCode parentName="p">{`getMenuProps`}</inlineCode>{`. `}<strong parentName="p">{`Please use it with extreme care and only if you are absolutely
sure that the ref is correctly forwarded otherwise `}<inlineCode parentName="strong">{`useCombobox`}</inlineCode>{` will
unexpectedly fail.`}</strong></p>
        <blockquote>
          <p parentName="blockquote">{`Note that for accessibility reasons it's best if you always render this
element whether or not downshift is in an `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` state.`}</p>
        </blockquote>
        <h5>{`getItemProps`}</h5>
        <p>{`The props returned from calling this function should be applied to any menu
items you render.`}</p>
        <p><strong parentName="p">{`This is an impure function`}</strong>{`, so it should only be called when you will
actually be applying the props to an item.`}</p>
        <p>{`Required properties:`}</p>
        <p>{`The main difference from vanilla `}<inlineCode parentName="p">{`Downshift`}</inlineCode>{` is that we require the items as
props before rendering. The reason is to open the menu with items already
highlighted, and we need to know the items before the actual render. It is still
required to pass either `}<inlineCode parentName="p">{`item`}</inlineCode>{` or `}<inlineCode parentName="p">{`index`}</inlineCode>{` to `}<inlineCode parentName="p">{`getItemProps`}</inlineCode>{`.`}</p>
        <ul>
          <li parentName="ul"><inlineCode parentName="li">{`item`}</inlineCode>{`: this is the item data that will be selected when the user selects a
particular item.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`index`}</inlineCode>{`: This is how `}<inlineCode parentName="li">{`downshift`}</inlineCode>{` keeps track of your item when updating the
`}<inlineCode parentName="li">{`highlightedIndex`}</inlineCode>{` as the user keys around. By default, `}<inlineCode parentName="li">{`downshift`}</inlineCode>{` will
assume the `}<inlineCode parentName="li">{`index`}</inlineCode>{` is the order in which you're calling `}<inlineCode parentName="li">{`getItemProps`}</inlineCode>{`. This
is often good enough, but if you find odd behavior, try setting this
explicitly. It's probably best to be explicit about `}<inlineCode parentName="li">{`index`}</inlineCode>{` when using a
windowing library like `}<inlineCode parentName="li">{`react-virtualized`}</inlineCode>{`.`}</li>
        </ul>
        <p>{`Optional properties:`}</p>
        <ul>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`ref`}</inlineCode>{`: if you need to access the item element via a ref object, you'd call the
function like this: `}<inlineCode parentName="p">{`getItemProps({ref: yourItemRef})`}</inlineCode>{`. As a result, the item
element will receive a composed `}<inlineCode parentName="p">{`ref`}</inlineCode>{` property, which guarantees that both
your code and `}<inlineCode parentName="p">{`useCombobox`}</inlineCode>{` use the same correct reference to the element.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`refKey`}</inlineCode>{`: if you're rendering a composite component, that component will need
to accept a prop which it forwards to the root DOM element. Commonly, folks
call this `}<inlineCode parentName="p">{`innerRef`}</inlineCode>{`. So you'd call: `}<inlineCode parentName="p">{`getItemProps({refKey: 'innerRef'})`}</inlineCode>{` and
your composite component would forward like: `}<inlineCode parentName="p">{`<li ref={props.innerRef} />`}</inlineCode>{`.
However, if you are just rendering a primitive component like `}<inlineCode parentName="p">{`<div>`}</inlineCode>{`, there
is no need to specify this property. It defaults to `}<inlineCode parentName="p">{`ref`}</inlineCode>{`.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`disabled`}</inlineCode>{`: If this is set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, then all of the downshift item event
handlers will be omitted. Items will not be highlighted when hovered, and
items will not be selected when clicked.`}</p>
          </li>
        </ul>
        <h5>{`getToggleButtonProps`}</h5>
        <p>{`Call this and apply the returned props to a `}<inlineCode parentName="p">{`button`}</inlineCode>{`. It allows you to toggle
the `}<inlineCode parentName="p">{`Menu`}</inlineCode>{` component.`}</p>
        <p>{`Optional properties:`}</p>
        <ul>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`ref`}</inlineCode>{`: if you need to access the button element via a ref object, you'd call
the function like this: `}<inlineCode parentName="p">{`getToggleButton({ref: yourButtonRef})`}</inlineCode>{`. As a result,
the button element will receive a composed `}<inlineCode parentName="p">{`ref`}</inlineCode>{` property, which guarantees
that both your code and `}<inlineCode parentName="p">{`useCombobox`}</inlineCode>{` use the same correct reference to the
element.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`refKey`}</inlineCode>{`: if you're rendering a composite component, that component will need
to accept a prop which it forwards to the root DOM element. Commonly, folks
call this `}<inlineCode parentName="p">{`innerRef`}</inlineCode>{`. So you'd call: `}<inlineCode parentName="p">{`getToggleButton({refKey: 'innerRef'})`}</inlineCode>{`
and your composite component would forward like:
`}<inlineCode parentName="p">{`<button ref={props.innerRef} />`}</inlineCode>{`. However, if you are just rendering a
primitive component like `}<inlineCode parentName="p">{`<div>`}</inlineCode>{`, there is no need to specify this property.
It defaults to `}<inlineCode parentName="p">{`ref`}</inlineCode>{`.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`disabled`}</inlineCode>{`: If this is set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, then all of the downshift button event
handlers will be omitted (it won't toggle the menu when clicked).`}</p>
          </li>
        </ul>
        <h5>{`getInputProps`}</h5>
        <p>{`This method should be applied to the `}<inlineCode parentName="p">{`input`}</inlineCode>{` you render. It is recommended that
you pass all props as an object to this method which will compose together any
of the event handlers you need to apply to the `}<inlineCode parentName="p">{`input`}</inlineCode>{` while preserving the ones
that `}<inlineCode parentName="p">{`downshift`}</inlineCode>{` needs to apply to make the `}<inlineCode parentName="p">{`input`}</inlineCode>{` behave.`}</p>
        <p>{`There are no required properties for this method.`}</p>
        <p>{`Optional properties:`}</p>
        <ul>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`disabled`}</inlineCode>{`: If this is set to true, then no event handlers will be returned
from `}<inlineCode parentName="p">{`getInputProps`}</inlineCode>{` and a `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` prop will be returned (effectively
disabling the input).`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`ref`}</inlineCode>{`: if you need to access the input element via a ref object, you'd call
the function like this: `}<inlineCode parentName="p">{`getInputProps({ref: yourInputRef})`}</inlineCode>{`. As a result, the
input element will receive a composed `}<inlineCode parentName="p">{`ref`}</inlineCode>{` property, which guarantees that
both your code and `}<inlineCode parentName="p">{`useCombobox`}</inlineCode>{` use the same correct reference to the
element.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`refKey`}</inlineCode>{`: if you're rendering a composite component, that component will need
to accept a prop which it forwards to the root DOM element. Commonly, folks
call this `}<inlineCode parentName="p">{`innerRef`}</inlineCode>{`. So you'd call: `}<inlineCode parentName="p">{`getInputProps({refKey: 'innerRef'})`}</inlineCode>{` and
your composite component would forward like: `}<inlineCode parentName="p">{`<input ref={props.innerRef} />`}</inlineCode>{`.
However, if you are just rendering a primitive component like `}<inlineCode parentName="p">{`<div>`}</inlineCode>{`, there
is no need to specify this property. It defaults to `}<inlineCode parentName="p">{`ref`}</inlineCode>{`.`}</p>
          </li>
        </ul>
        <p>{`In some cases, you might want to completely bypass the `}<inlineCode parentName="p">{`refKey`}</inlineCode>{` check. Then you
can provide the object `}<inlineCode parentName="p">{`{suppressRefError : true}`}</inlineCode>{` as the second argument to
`}<inlineCode parentName="p">{`getInput`}</inlineCode>{`. `}<strong parentName="p">{`Please use it with extreme care and only if you are absolutely
sure that the ref is correctly forwarded otherwise `}<inlineCode parentName="strong">{`useCombobox`}</inlineCode>{` will
unexpectedly fail.`}</strong></p>
        <h5>{`getComboboxProps`}</h5>
        <p>{`This method should be applied to the `}<inlineCode parentName="p">{`input`}</inlineCode>{` wrapper element. It has similar
return values to the `}<inlineCode parentName="p">{`getRootProps`}</inlineCode>{` from vanilla `}<inlineCode parentName="p">{`Downshift`}</inlineCode>{`, but renaming it as
it's not a root element anymore. We are encouraging the correct `}<inlineCode parentName="p">{`combobox`}</inlineCode>{` HTML
structure as having the combobox wrapper as a root for the rest of the elements
broke navigation and readings with assistive technologies. The wrapper should
contain the `}<inlineCode parentName="p">{`input`}</inlineCode>{` and the `}<inlineCode parentName="p">{`toggleButton`}</inlineCode>{` and it should be on the same level
with the `}<inlineCode parentName="p">{`menu`}</inlineCode>{`.`}</p>
        <p>{`There are no required properties for this method.`}</p>
        <p>{`In some cases, you might want to completely bypass the `}<inlineCode parentName="p">{`refKey`}</inlineCode>{` check. Then you
can provide the object `}<inlineCode parentName="p">{`{suppressRefError : true}`}</inlineCode>{` as the second argument to
`}<inlineCode parentName="p">{`getComboboxProps`}</inlineCode>{`. `}<strong parentName="p">{`Please use it with extreme care and only if you are
absolutely sure that the ref is correctly forwarded otherwise `}<inlineCode parentName="strong">{`useCombobox`}</inlineCode>{` will
unexpectedly fail.`}</strong></p>
        <h4>{`Actions`}</h4>
        <p>{`These are functions you can call to change the state of the downshift
`}<inlineCode parentName="p">{`useCombobox`}</inlineCode>{` hook.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`closeMenu`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`function()`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`closes the menu`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`openMenu`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`function()`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`opens the menu`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`selectItem`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`function(item: any)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`selects the given item`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`setHighlightedIndex`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`function(index: number)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`call to set a new highlighted index`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`setInputValue`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`function(value: string)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`call to set a new value in the input`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`toggleMenu`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`function()`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`toggle the menu open state`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reset`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`function()`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`this resets downshift's state to a reasonable default`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`State`}</h4>
        <p>{`These are values that represent the current state of the downshift component.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`highlightedIndex`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`number`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the currently highlighted item`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`isOpen`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the menu open state`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`selectedItem`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`any`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the currently selected item input`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`inputValue`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the value in the input`}</td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      